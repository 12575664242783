.faqs{
	//font-family: "PT Sans";
	a.list-group-item{
		transition: all .3s ease;
		color: #00aa9d;
		border-color: #e6e6e6;
		font-size: 16px;
		font-weight: bold;
	}
	a.list-group-item:focus, a.list-group-item:hover {
		color: #00aa9d;
		background-color: #dffffb;
		border-color: #e6e6e6;
	}
	.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
		color: #fff;
		background-color: #00aa9d;
		border-color: #e6e6e6;
	}
	.panel-heading{
		transition: all .3s ease;
	}
	.panel-group .panel+.panel {
	    margin-top: 30px;
	}
	.panel-heading {
	    padding: 15px;
	}
	.panel-default>.panel-heading {
	    color: #00aa9d;
	    background-color: #fff;
	    border-color: #e6e6e6;
	}
	.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
	    border-top: 1px solid #e6e6e6;
	    color: #b3b3b3;
	}
	.panel-title {
	    font-weight: bold;
	}
	.panel-heading:hover, .panel-heading:focus {
	    background: #dffffb;
	}
	.h-faqs{
		font-weight: bold;
		color: #00aa9d;
		margin-bottom: 30px;
	}
	.text-primary {
		font-weight: bold;
		color: #00aa9d;
	}
}





body{
				background: #f1f1f1;
				//font-family: "PT Sans";
			}
			a.list-group-item{
				transition: all .3s ease;
				color: #00aa9d;
				border-color: #e6e6e6;
				font-size: 16px;
				font-weight: bold;
			}
			a.list-group-item:focus, a.list-group-item:hover {
				color: #00aa9d;
				background-color: #dffffb;
				border-color: #e6e6e6;
			}
			.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
				color: #fff;
				background-color: #00aa9d;
				border-color: #e6e6e6;
			}
			.panel-heading{
				transition: all .3s ease;
			}
			.panel-group .panel+.panel {
			    margin-top: 30px;
			}
			.panel-heading {
			    padding: 15px;
			}
			.panel-default>.panel-heading {
			    color: #00aa9d;
			    background-color: #fff;
			    border-color: #e6e6e6;
			}
			.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body {
			    border-top: 1px solid #e6e6e6;
			    color: #b3b3b3;
			}
			.panel-title {
			    font-weight: bold;
			}
			.panel-heading:hover, .panel-heading:focus {
			    background: #dffffb;
			}
			.h-faqs{
				font-weight: bold;
				color: #00aa9d;
				margin-bottom: 30px;
			}
			.text-primary {
				font-weight: bold;
				color: #00aa9d;
			}