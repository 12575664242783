.register_success{
	padding: 50px 0;
	.text_content{
		padding-bottom: 30px;
		h1{
			font-weight: bold;
			text-align: center;
		}
		p{
			text-align: center;
		}
	}
	@include images;
	.btn_content{
		text-align: center;
		@include btn-download;
		
	}

}