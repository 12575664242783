.navbar-default{
	background: $primary-color-dark;
	border: none;
	margin-bottom: 0;
	@media screen and (min-width: 992px){
		height: 80px;
	}
	.navbar-header{
		.navbar-toggle{
			border-color: white;
			background: $secondary-color;
			.icon-bar{
				background-color: white;
			}
		}
		.navbar-brand{
			@media screen and (min-width: 992px){
				line-height: 50px;
			}
		}
	}
	.navbar-collapse{
		background: $primary-color-light;
		@media screen and (min-width: 768px){
			background: $primary-color-dark;
		}
		border: none;
		.navbar-nav{
			li{
				a{
					color: white;
					padding: 15px 20px;
					@media screen and (min-width: 992px){
						line-height: 50px;
					}
				}
				a:hover, a:focus {
					background: rgba(0,0,0, .15);
				}
				.btn-download{
					background-color: $secondary-color;
					@media screen and (min-width: 992px){
						line-height: 22px;
						margin-top: 14px;
						margin-left: 15px;
					}

				}
				.dropdown-toggle{
					background: transparent;
				}
				.dropdown-menu{
					padding: 0px;
					li{
						a{
							background: $primary-color-dark;
						}
					}
				}

			}
		}
	}
}
