footer{
	color: $primary-color-text;
	.social_networks{
		padding: 15px 0px;
		background-color: $primary-color-dark;
		font-size: 16px;
		font-weight: 400;
		figure{
			img{
				width: 35px;
			}
		}
		.red_social{
			width: 100%; 
			text-align: center;
    		display: inline-block;
    		margin-top: -10px;
   		}

		#twitter:before{
			content:  url($path+'img/svg/twitter.svg');
			position: relative;
			top: 10px;
			left: 0px;
		}
		#google:before{
			content:  url($path+'img/svg/twitter.svg');
			position: relative;
			top: 10px;
			left: 0px;
		}
		#linkedin:before{
			content:  url($path+'img/svg/twitter.svg');
			position: relative;
			top: 10px;
			left: 0px;
		}
		#facebook:before{
			content:  url($path+'img/svg/facebook.svg');
			position: relative;
			top: 10px;
			left: 0px;
			width: 32px;
		}
		#youtube:before{
			content:  url($path+'img/svg/twitter.svg');
			position: relative;
			top: 10px;
			left: 0px;
		}
		#instagram:before{
			content:  url($path+'img/svg/instagram.svg');
			position: relative;
			top: 10px;
			left: 0px;
			width: 32px;
		}
		a {color: white;}
	}
	.footer_container{
		background-color: $primary-color-dark-2;
		padding: 30px 0;
		ul{
			padding: 0px;
			li{
				margin-top: 10px;
				list-style: none;
			}
			li:first-child{
				font-size: 18px;
				font-weight: 700;
			}
		}
		a{
			color: $primary-color-text;
		}
		.hidden_col{
			display: none;
		}
		
	}
	.belt{
		padding: 20px 0px;
		background-color: $primary-color-dark-3;
		.slogan{
			// padding: 20px 0px;
			text-align: center;
		}
		.copy_right{
			// padding: 20px 0px;
			text-align: center;
		}
	}
}

@media screen and (min-width: 600px){

}

@media screen and (min-width: 767px){
	footer{
		.social_networks{
			.red_social{
				width: 49%
			}
		}
	}
}
@media screen and (min-width: 992px){
	footer{
		.social_networks{
			.red_social{
				width: 18.72%;
			}
		}
	}
}
@media screen and (min-width: 1240px){
	footer{
		.footer_container{
			.hidden_col{
				display: block;
			}
		}
		.belt{
			// height: 100px;
			.slogan{

				text-align: left;
			}
			.copy_right{

				text-align: right;
			}
		}
	}
	
}
.label-danger {background-color: $clr-naranja;vertical-align: middle;}