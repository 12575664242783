.form_login{
	padding-top: 60px;
	padding-bottom: 60px;
	form{
		padding: 30px;
		.form-control{
			border-radius: 0px;
		}
		@include btn-download;
			.btn-download{
				margin-top: 0px;
			}
		.btn-link {color: $primary-color;text-align: right;}
	}
	.panel-default {border: none;box-shadow: 1px 1px 1px rgba(0,0,0, .2);}
}

.reset{
	 margin-top: 100px;
	 margin-bottom: 100px;
	.panel-default{
		.panel-heading{
			background: $primary-color;
			color:white;
		}
		.panel-body{
			 @include btn-download;
			 .privacy{
			 	h4{
			 		color :$primary-color;
			 	}
			 }
		}
	}
	
}