// Variables
@import "variables";
@import "background_test";
@import "mixins";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "web/header";
@import "web/home";
@import "web/register";
@import "web/footer";
@import "web/login";
@import "web/faqs"; 
@import "web/register-success"; 
@import "web/plots"; 

