@mixin images{
	.img_content{
		margin-bottom: 15px;
		figure{
			img{
				width: 100%;
			}
		}
	}
}
@mixin btn-download{
	.btn-download{
		margin-top: $margin-lg;
		color: white;
		background-color: $secondary-color;
		@media screen and (min-width: 992px){
			line-height: 22px;
			margin-top: 14px;
		}
	}
	.btn-download:hover{
		background-color: $secondary-color-dark; 
	}
	.btn-naranja:hover{
		background-color: $secondary-color; 
	}
}

@mixin icon{
	@media screen and (min-width: 600px){
		position: relative;
		top:10px;
	}
}