.plots_section{
	//background-color: #cacaca; //test
	margin: 30px;
	.row{
		.titles{
			h4{
				color: $primary-color-dark;
				font-size: 38px;
			}
		}
		.btns{
			text-align: right;
			button{
				    background-color: $secondary-color;
    				border-color: $secondary-color-dark;
			}
		}
	}
	.dataTables_wrapper{
		font-size: 15px;
		.dataTables_length{
			label{
				font-weight: bold;
			}
			
			select{
				border: 1px solid $divider-color;
			}
		}
		.dataTables_filter{
			label{
				font-weight: bold;
			}
			input{
				border: 1px solid $divider-color;
				border-radius: 0px;
			}
		}
		table{
			border: none;
			td{
				border: none;
			}
			tr{
				border: none;
			}
			th{
				border: none;
			}
			thead{
				background-color: $primary-color-dark;
    			color: $primary-color-text;
			}
			tbody{
				.odd{
					background-color: $primary-color-light-3;
				}
				.even{
					background-color: $primary-color-text;
				}
			}
		}
		.dataTables_info{
			width: 50%;
			display: inline;
			font-weight: bold;
		}
		.dataTables_paginate{
			width: 50%;
			display: inline;
			text-align: right;
			font-weight: bold;
			a{
				color: $secondary-color-text;
			}
		}

	}	
}

.modal-lg{
	width: 1140px;
	
}

