.coin:before{
	content:  url($path+'img/png/fia_moneda.png');
	margin-right: 10px;
	top: 6px;
	position: relative;
}
.done:before{
	content:  url($path+'img/svg/done.svg');
	margin-right: 10px;
	top: 6px;
	position: relative;
}
.c_icon_1:before{
	content:  url($path+'img/png/fia_icn-certificados1.png');
	margin-right: 10px; 
	@include icon;
}
.c_icon_2:before{
	content:  url($path+'img/png/fia_icn-certificados2.png');
	margin-right: 10px;
	@include icon;
}
.c_icon_3:before{
	content:  url($path+'img/png/fia_icn-certificados3.png');
	margin-right: 10px;
	@include icon;
}
.c_icon_4:before{
	content:  url($path+'img/png/fia_icn-certificados4.png');
	margin-right: 10px;
	@include icon;
}
.r_icon_1:before{
	content:  url($path+'img/png/fia_icn-segs1.png');
	margin-right: 10px; 
	@include icon;
}
.r_icon_2:before{
	content:  url($path+'img/png/fia_icn-segs2.png');
	margin-right: 10px;
	@include icon;
}
.r_icon_3:before{
	content:  url($path+'img/png/fia_icn-segs3.png');
	margin-right: 10px;
	@include icon;
}
.rsi_icon_1:before{
	content:  url($path+'img/png/fia_icn-rsi1.png');
	margin-right: 10px; 
	position: relative;
	top: 6px;
}
.rsi_icon_2:before{
	content:  url($path+'img/png/fia_icn-rsi2.png');
	margin-right: 10px;
	position: relative;
	top: 6px;
}
.rsi_icon_3:before{
	content:  url($path+'img/png/fia_icn-rsi3.png');
	margin-right: 10px;
	position: relative;
	top: 6px;
}

.about_fia{
	padding: 60px 0;
	background: $primary-color;
	@include images;
	@media screen and (min-width: 992px){
		.logo_fia{
			margin-bottom: 60px;
			img {max-height: 125px;}
		}
	}
	.text_content{
		p{
			color:white;
			margin-top: $margin-sm;
		}
		h5{
			color: white;
			margin-top: $margin-sm;
		}
		@include btn-download;
	}
}

.about_austar{
	padding: 90px 0;
	background: $secondary-color-light;
	color: white;
	vertical-align: middle;
	@media screen and (max-width: 992px){
		.text-right {text-align: left !important;}
	}
	@include images;
	.austar-producto {margin-top: 60px;}
	.text_content{
		margin-top: 60px;
		a {
			color: white;
			display: block;
			text-decoration: underline;
			margin-top: 60px;
		}
		a:hover {color: rgba(255,255,255, .5);text-decoration: none;}
	}
}

.app_movil_web{
	background: $primary-color-dark;
	@media screen and (min-width: 992px){
		max-height: 190px;
		overflow: hidden;
	}
	
	.img_content{
		figure{
			line-height: 185px;
			img{
				width: 100%;
				
				@media screen and (min-width: 500px) {
					max-height: 185px;
					width: auto;
				}
			}
		}
	}
	.text_content{
		color:white;
		h2{
			font-weight: bold;
			margin-top: 0;
			margin-bottom: 0;
			@media screen and (min-width: 500px) {
				margin-top: 50px;
			}
			
		}
		h3{
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.rsi{
	padding-top: 90px;
	background: $primary-color-light;
	@media screen and (min-width: 992px){
		max-height: 720px;
	}
	@include btn-download;
	@include images;
	.phone{
		margin-bottom: 0px;
	}
	.text_content:first-child {padding-top: 15px;}
	.text_content{
		@media screen and (min-width: 1200px){
			padding-top: 50px;
		}
		color: white;
		.btn-download{
			margin-bottom: 30px;
		}
	}
}
.certificates{
	padding: 90px 0;
	background: $secondary-color-light;
	@media screen and (min-width: 992px){
		max-height: 720px;
	}
	@include btn-download;
	@include images;
	.text_content{
		color: white;
		h2{
			font-weight: bold;
		}
		.center{
			text-align: center;
		}
	}
}
.btn-naranja {background-color: $clr-naranja !important;}
.register{
	padding-top: 90px;
	background: $primary-color-light-2;
	@media screen and (min-width: 992px){
		max-height: 720px;
	}
	@include btn-download;
	@include images;
	.phone{
		margin-bottom: 0px;
	}
	.text_content:first-child{
		padding-top: 0;}
	.text_content{
		color: white;
		h2{
			margin-top: 0;
			font-weight: bold;
		}
		@media screen and (min-width: 1200px){
			padding-top: 50px;
		}
		.btn-download{
			margin-bottom: 30px;
		}
	}
}
.contact{
	padding: 90px 0;
	background: $primary-color-dark;
	@media screen and (min-width: 992px){
		max-height: 1060px;
	}
	.text_content{
		color: lighten(white, 30%);
		h2{
			color:white;
			font-weight: bold;
		}
	}
	@include btn-download;
	form{
		.form-control{
			border-radius: 0px;
		}
	}
}

.banner_app{
	padding: 60px 0;
	background: $secondary-color-light;
	.text_content{
		color:white;
		line-height: 1;
		font-size: 21px;
		p:last-child {
			margin-bottom: 0;
		}
	}
}
