@font-face {
	font-family: "PT Sans";
	font-style: normal;
	font-weight: 700;
	src: local("PT Sans"), url("../fonts/PT_Sans-Web-Bold.ttf") format("truetype");
	unicode-range: U+000-5FF ;//Latin glyphs
}
@font-face {
	font-family: "PT Sans";
	font-style: normal;
	font-weight: 400;
	src: local("PT Sans"), url("../fonts/PT_Sans-Web-Regular.ttf") format("truetype");
	unicode-range: U+000-5FF; //Latin glyphs
}

// Body
$body-bg: #f1f1f1;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "PT Sans", sans-serif;
$font-size-base: 18px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$primary-color-dark-3: 		#008a80;
$primary-color-dark-2: 		#00998e;
$primary-color-dark:   		#00aa9d;
$primary-color:        		#30bab1;
$primary-color-light:  		#2fb9b0;
$primary-color-light-2: 	#51cbc4;
$primary-color-light-3: 	#e2fffa;


$primary-color-text:   		#FFFFFF;
$secondary-color-text: 		#666666;

$secondary-color-dark:		#FF583E;
$secondary-color:         	#ff9200;
$secondary-color-light:    	#ffa940;
$secondary-color-light-2:  	#FDE9D2;

$divider-color:        		#999999;
$divider-color-light:   	#E3E7E6;

$accent-color:         		#29ABE2;
$clr-naranja:				#ff5a19;

//$path:"/dw.fia/public/";
$path:"/public/";
$title-lg: 50px;
$title-sm: 40px;

$subtitle-lg: 40px;
$subtitle-sm: 30px;

$text-lg: 25px;
$text-sm: 20px;

$margin-lg: 40px;
$margin-sm: 20px;
$padding-lg: 30px;
$padding-sm: 10px;

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
