body{
	background-color: #FFD1D1; /* Solo para efectos de prueba */
}
@media screen and (min-width: 600px){
	body{
		background: #FFFE94; /* Solo para efectos de prueba */
	}
}

@media screen and (min-width: 767px){
	body{
		background: #BEFF88; /* Solo para efectos de prueba */
	}
}
@media screen and (min-width: 992px){
	body{
		background: #81F8FF; /* Solo para efectos de prueba */
	}
}
@media screen and (min-width: 1240px){
	body{
		background: #FFFFFF; /* Solo para efectos de prueba */
	}
}