.title_register{
	padding-top: 60px;
	a {color: $primary-color;}
	.text_content{
		h1{
			font-size: 48px;
			font-weight: regular;
			text-align: center;
			margin-top: 0;
		}
		p{
			font-size: 18px;
			font-weight: regular;
			text-align: center;
			margin-bottom: 0;
		}
	}
}
.form_register{
	form{
		.row{
			padding: $padding-lg 0;
			.form-control{
				border-radius: 0px;
			}
			@include btn-download;
		}
	}
}